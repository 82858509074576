import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import CustomerDetails from './CustomerDetails'
import SampleDetails from './SampleDetails'
import InvoiceDetails from './InvoiceDetails'
import line from '.././assets/images/line.png'

const labelfontfamily = 'Times-Bold'

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '2',
        width: '100%',
        flexDirection: 'column',
    },
    head: {
        display: 'flex',
        textTransform: 'uppercase',
        fontSize: 10.5,
        fontFamily: labelfontfamily,
        //marginTop: 10
    },
})

const Details = ({
    data,
    details,
    type,
    titleName,
    standard_ref,
    organizationName,
    result,
    jobNo,
    groupType,
    id,
}) => {
    let projectDetails = details?.customer_samples?.projects
    let customerRef = details?.customerReference

    return (
        <View
            style={{
                flexDirection: 'coloumn',
                justifyContent: 'space-between',
                marginTop: 2,
                display: 'flex',
            }}
        >
            <View style={styles.container}>
                {type === 'report' ||
                    type === 'reportOnly' ||
                    type === 'testReport' ? (
                    <>
                        <Text style={styles.head}>Customer Information :</Text>
                        <CustomerDetails
                            data={data}
                            customerRef={customerRef}
                            projectDetails={projectDetails}
                            organizationName={organizationName}
                            groupType={groupType}
                            type={type}
                            titleName={titleName}
                            id={id}
                        />
                    </>
                ) : null}
                {groupType !== 'DYNAMIC_JOBCARD' && (
                <View style={{ marginBottom: '2px', marginTop: '2px' }} fixed>
                    <Image src={line} />
                </View>
                )}
            </View>
            <View style={styles.container}>
                <Text style={styles.head}> * Sample Details : </Text>
                <SampleDetails
                    details={details}
                    type={type}
                    titleName={titleName}
                    standard_ref={standard_ref}
                    organizationName={organizationName}
                    jobNo={jobNo}
                    groupType={groupType}
                />
                <View style={{ marginBottom: '2px', marginTop: '2px' }} fixed>
                    <Image src={line} />
                </View>
            </View>
            {details?.invoice_id?.invoiceStatus === 'show' &&
                (details?.invoice_id?.invoice_no ||
                    details?.invoice_id?.status ||
                    details?.invoice_id?.paymentPaid ||
                    details?.invoice_id?.paymentGP) ? (
                <View style={styles.container}>
                    <Text style={styles.head}> Invoice Details : </Text>
                    <InvoiceDetails
                        details={details}
                        type={type}
                        titleName={titleName}
                        standard_ref={standard_ref}
                    />
                </View>
            ) : null}
        </View>
    )
}

export default Details

{
    /* <CustomerDetails data={data} jobNo={jobNo} /> */
}
{
    /* <Text style={{ justifyContent: 'flex-start' }} >Sample Details</Text> */
}
{
    /* <SampleDetails style={styles.sampleContainer} details={details} /> */
}

{
    /* <CustomerDetails data={data} jobNo={jobNo} /> */
}
{
    /* <Text style={{ justifyContent: 'flex-start' }} >Sample Details</Text> */
}
{
    /* <SampleDetails style={styles.sampleContainer} details={details} /> */
}
